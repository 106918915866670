import React, { useEffect, useState } from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './reports.scss'
import { reportsref } from '../../../Firebase/Firebase';
import {getDocs} from "firebase/firestore";
import { Link } from 'react-router-dom';







const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Reports = () => {
  // Get Reports Start


  const [data, setData] = useState([]);

  useEffect(()=>{
      async function getData(){
          const _data = await getDocs(reportsref)
         _data.forEach((element) => {
          setData((prv)=>[...prv,{data:element.data(), id:element.id}])

         });

      }
      getData()

  },[]);


  // Get reports en
  
  return (
    <>
    <div >
    
    <div className='Section'>
    <Navigationbar/>
    <div className='center'>
    <div className="title">
            <h3>Reports</h3>
          </div>
          <div className='newscontainer'>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                {data.map((e, i) => (
                  <Grid item xs={12} sm={12} md={6} key={i}>
                 
                       
                          <Item >
                          <h5>{e.data.year}</h5>
                          <h5>{e.data.title}</h5>
                          {/* <p>hello this is news </p> */}
                          <button className="button"> <Link to={e.data.file}>View Report</Link>
                          </button>
                        </Item>
                          
                       
                    
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </div>

   
    </div>
    <div className='Section footerfullscreeen'>
          <Footer />
        </div>
    </div></>
  )
}

export default Reports
