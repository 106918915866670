import React from "react";
import Navigationbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./news.scss";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { newsref } from "../../../Firebase/Firebase";
import { getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const News = () => {
  // Get News data from firebase database

  const [data, setData] = useState([]);
  const [state, setState] = useState(false);

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(newsref);
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }]);
      });
     
    }
    getData();
  }, [state]);


  // Get News data from firebase end

  return (
    <>
      <div>
        <Navigationbar />
        <div className="center">
          <div className="title">
            <h3>News</h3>
          </div>
          <div className="newscontainer">
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {data.map((e, i) => (
                  <Grid item xs={2} sm={4} md={4} key={i}>
                    <Item>
                      {/* <h5>2080</h5> */}
                      <h5>{e.data.title}</h5>
                      {/* <a className="button"><Link to={e.data.file}>Read More</Link></a> */}


                      <button class="button"><Link to={e.data.file}>Read More</Link></button>
                      
                    </Item>
 
                  </Grid>
                ))}
                {/* {Array.from(Array(6)).map((_, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Item>
                      <h5>2080</h5>
                      <h5>कम्पनीको संञ्चालक समितिको सदस्यमा समुह “ख” वाट विजयी उम्मेदवारहरुको घोषणा सम्वन्धमा ।</h5>
                   
                      <button class="button"> Read More
                      </button>
                    </Item>
                  </Grid>
                ))} */}
              </Grid>
            </Box>
          </div>
        </div>
        <div className="Section footerfullscreeen">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default News;
