import React, {useEffect, useState} from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import './Visionmission.scss'
import { getDocs } from 'firebase/firestore'
import { visionandmissionref } from '../../../Firebase/Firebase';


const Visionmission = () => {
   // Get about company from firebase database

const [data, setData] = useState([])
    
useEffect(()=>{
    async function getData(){
        const _data = await getDocs(visionandmissionref)
       _data.forEach((element) => {
        setData((prv)=>[...prv,{data:element.data(), id:element.id}])
       });
       
       
    }
    getData()
   


},[]);

// Get about company from firebase end
  return (
    <>
    <div className='fullscroll'>
    
    <div className='Section'>
    <Navigationbar/>
    <div className='visionmission'>
      {
        data.map((e)=>(
          <>
          <div className='vision'>
        <h1>Vision</h1>
        <p dangerouslySetInnerHTML={{ __html:e.data.vision}}></p>
        
      </div>
      <div className='Mission'>
        <h1>Mission</h1>
        <span dangerouslySetInnerHTML={{ __html:e.data.mission}}></span>

      </div>
          </>

        ))
      }
      
    </div>
    </div>
   
    <div className='Section footerfullscreeen'>
      <Footer/>
    </div>
    </div></>
  )
}

export default Visionmission
