import React, { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as bs from "bootstrap/dist/css/bootstrap.css";
import "./Navbar.scss"
import nepalflag from "../../../../Assets/nepalflag.gif";
import fulllogo from "../../../../Assets/fulllogo.png";
import {Link } from 'react-router-dom'
import { Navigate } from "react-router-dom";
// import AdminNavigationBar from '../../../Admin/Admin-Panel/Navigation Bar/nav';
import { getDocs } from 'firebase/firestore'
import { projectref } from '../../../Firebase/Firebase';






class Navigationbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date().toLocaleString(),
    });
  }
  

  render() {
    
   
    
    return (
      <div id="topnav">
        <div className="clock ">
          <div className="center">{this.state.time}</div>
        </div>

        <div className="center ">
          
          <div className=" logo-container">
            <Link to="/">
              <div className="fulllogo">
                <img src={fulllogo} alt="fulllogo" />
              </div>
            </Link>
            
              <div className="nepalflag">
                <img src={nepalflag} alt="nepalflag" />
              </div>
            
          </div>
        </div>
        

        <div className="navigation">
          <Navbar bg="dark" variant="dark" expand="lg" className="m-0">
            <div className="center">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  {/* <Nav.Link href="about-us">About Us</Nav.Link> */}
                  <NavDropdown title="About us" id="basic-nav-dropdown">
                    <NavDropdown.Item href="about-us">
                      About Company
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/vision-mission">
                      Vision and Mission
                    </NavDropdown.Item>
                    <NavDropdown.Item href="board-of-director">
                      Board of Directors
                    </NavDropdown.Item>
                    <NavDropdown.Item href="management-team">
                      Management Team
                    </NavDropdown.Item>
                    
                    {/* <NavDropdown.Divider /> */}
                  </NavDropdown>
                  <NavDropdown title="Project" id="basic-nav-dropdown">
                    <NavDropdown.Item href="bhim-khola">
                      Project 1 (Bhim Khola 4.96 MW)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="lower-bhim-khola">
                      Project 2 (Lower Bhim Khola 6.05 MW)
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="gallery">Gallery</Nav.Link>
                  <NavDropdown title="News" id="basic-nav-dropdown">
                    <NavDropdown.Item href="news">
                      News
                    </NavDropdown.Item>
                    <NavDropdown.Item href="report">
                      Report
                    </NavDropdown.Item>
                  </NavDropdown>
                  
                  <Nav.Link href="contact-us">Contact us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
        {/* <AdminNavigationBar/> */}
      </div>
    );
}};




export default Navigationbar;
