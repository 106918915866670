import React, { useEffect, useState } from 'react'
import Movingcarousel from '../../Components/Carousel/Carousel';
import "./Home.scss"
import Navigationbar from '../../Components/Navbar/Navbar'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Footer from '../../Components/Footer/Footer';
import img1 from '../../../../Assets/IMG10.jpg'
import { getDocs, query, limit } from 'firebase/firestore'
import { companyoverviewref,galleryref } from '../../../Firebase/Firebase';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Form, Col, Row, Button } from "react-bootstrap";
import {Link } from 'react-router-dom'




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Home = () => {
  // Get about company from firebase database

  const [data, setData] = useState([])
  const [gallerydata, setGallerydata] = useState([])

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(companyoverviewref)
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }
    async function getGallerydata() {
      const _data = await getDocs(query(galleryref, limit(4)))
      _data.forEach((element) => {
        setGallerydata((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }


    getData()
    getGallerydata()



  }, []);

  // Get about company from firebase end
  return (
    <>

      <div className='fullscroll fullscrollehome'>

        <div className='Section'>
          <Navigationbar />

          <Movingcarousel />

        </div>
        <div className='Section'>
          <div className='text-container'>

            <h1>Company Overview</h1>
            {data.map((e) => (
              <p dangerouslySetInnerHTML={{ __html: e.data.Companyoverview }}></p>
            ))}
          <a href='about-us'>

            <button class="button"> Read More
            </button>
          </a>
          </div>
        </div>
        <div className='Section imageview'>
          <div>
            <h1>Images</h1>
            
              <ImageList sx={{ width: 1000, height: 250 }} cols={4} rowHeight={100}>
                {
                 

                  gallerydata.map((e,i)=>(
                    
                    <ImageListItem key={i}>
                      <img
                      src={`${e.data.Image}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${e.data.Image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={e.Title}
                      loading="lazy"
                      
                      />
                    </ImageListItem>
                    
                    
                    ))
                  }
            </ImageList>

            
          <a href='gallery'>

            <button class="button" >Gallery >>
            </button>
          </a>
          </div>
        </div>


        <div className='Section'>
          <div className="map">
            <div className='center'>


            </div>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.6382298756334!2d85.31991792908372!3d27.739617692841442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19cbc22cf633%3A0x6fec8b88900b24a5!2sShikhar%20Power%20Development%20Pvt.%20Ltd.!5e0!3m2!1sne!2snp!4v1602847757288!5m2!1sne!2snp"
              className='mapdata'
            ></iframe> */}
            <Box>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                <Grid item xs={6} md={6} sm={12}>
                  <Item>
                    <h1>Find Us</h1>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.6382298756334!2d85.31991792908372!3d27.739617692841442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19cbc22cf633%3A0x6fec8b88900b24a5!2sShikhar%20Power%20Development%20Pvt.%20Ltd.!5e0!3m2!1sne!2snp!4v1602847757288!5m2!1sne!2snp"
                      className='mapdata'
                    ></iframe></Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <h1>Contact Us</h1>
                    <Form style={{width:'100%', flex: '1'}} >
        <Form.Group size="sm" as={Row} controlId="formPlaintextFname">
        <Form.Label column sm="3">
          <h5>

            Full Name:
          </h5>
          </Form.Label>
          <Col sm="8">
            <Form.Control type="text" placeholder="Your Name"
           
          />
          </Col>
        </Form.Group>
        <Form.Group size="sm" as={Row} controlId="formPlaintextPnum">
          <Form.Label column sm="3">
            <h5>

            Phone No:
            </h5>
          </Form.Label>
          <Col sm="8">
            <Form.Control type="number" placeholder="98********" 
            
            />
          </Col>
        </Form.Group>
        <Form.Group size="sm" as={Row} controlId="formPlaintextEmail">
          <Form.Label column sm="3">
            <h5>

            Email:
            </h5>
          </Form.Label>
          <Col sm="8">
            <Form.Control type="email" placeholder="Your Mail Address" 
            
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            <h5>Comments:</h5></Form.Label>
          <Form.Control as="textarea" rows="3" 
          
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>

                    </Form>

                  </Item>
                </Grid>

              </Grid>
            </Box>

          </div>


        </div>
        <div className='Section footerfullscreeen'>
          <Footer />
        </div>


      </div>
    </>
  )
}


export default Home
