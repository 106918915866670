import { getFirestore, collection,doc } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBscgLEo_r6iWzCWjNc0mKuHSicZgq4oGI",
  authDomain: "shikharv2.firebaseapp.com",
  projectId: "shikharv2",
  storageBucket: "shikharv2.appspot.com",
  messagingSenderId: "811842004651",
  appId: "1:811842004651:web:3d1513de46615f3f0839ee"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app)
export const carosealref = collection(db, "carousel")
// For Carousel image 
export const storage = getStorage(app)
export const carouselstorageref = ref(storage, "carouselimage/")
// End for carousel image
// For CompanyOverview
export const companyoverviewref = collection(db, "CompanyOverview")
//  For companyoverview End
// for about company
export const aboutcompanyref = collection(db, "Aboutcompany")
// for about company end
// for vision and mission
export const visionandmissionref = collection(db, "Visionandmission")
// for vision and mission end
// for board of director
export const boardofdirectorref = collection(db, "Boardofdirector")
// for board of director end
// for projects
export const projectref = collection(db,"project")
// for project end
// for message from
export const messagefromref= collection(db,"Messagefrom")
// for message from end
// for management team
export const managementteamref= collection(db,"managementteam")
// for management team end
// for Gallery
export const galleryref= collection(db,"Gallery")
// for Gallery end
// for News
export const newsref= collection(db,"News")
// for News end
// for reports
export const reportsref= collection(db,"Reports")
// for reports end
