import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import './Gallery.scss'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { galleryref } from '../../Firebase/Firebase';
import swal from 'sweetalert'
import { getStorage, uploadBytes, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { addDoc, getDocs, doc, deleteDoc } from 'firebase/firestore'
import {useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/Firebase";
import { signOut } from "firebase/auth";





const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// For Dialog Box to add Data

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// End ffor dialog box to add data

const Gallery = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //   For Dialog Box to add Data
  const [openform, setOpenform] = React.useState(false);

  const handleOpenform = () => {
    setOpenform(true);
  };

  const handleCloseform = () => {
    setOpenform(false);
  };

  // End dialog box to add data


  const [img, setImg] = useState('')

  async function handlesubmit(){
    if(img==''){
      alert('Please Select Image')
    }else{
   
    try{
    

    const galleryimgref = ref(getStorage(), 'Gallery/' + img.name)
      const upload = uploadBytesResumable(galleryimgref,img)
      setImg('')
    await upload.on('state_changed',(snapshot)=>{
      console.log('working') 
        const progess= (snapshot.bytesTransferred/snapshot.totalBytes)
        console.log(progess)
    },
    (err)=>{
      alert(err)
    },
    ()=>{
      getDownloadURL(upload.snapshot.ref).then((downloadurl)=>{
        const newUploadData={
          Image: downloadurl

        }
        if(newUploadData.image!==""){
          try{
  
            addDoc(galleryref, newUploadData);
            swal({
                          title: "Successfully Carousel Added",
                          icon: "success",
                          button: false,
                          timer: 3000
                      })
  
      }catch(err){
        swal({
                      title: err,
                      icon: "error",
                      button: false,
                      timer: 3000
                  })
  
      }



    }})})
    }catch(err){
      swal({
                    title: err,
                    icon: "error",
                    button: false,
                    timer: 3000
                })

    }
    if(state == true){
      setState(false)
  }else{
      setState(true)
  }
}
      
    
  }



  // Get Gallery data from firebase database



  

const [data, setData] = useState([])
const [state, setState] = useState(false)
    
    useEffect(()=>{
        async function getData(){
            const _data = await getDocs(galleryref)
           _data.forEach((element) => {
            setData((prv)=>[...prv,{data:element.data(), id:element.id}])
            
           });
           
           
        }
        getData()


    },[state]);
    
// Get Gallery data from firebase end

// Delete data from firebase

async function deleteGallerydata(id){
  try{

      await deleteDoc(doc(galleryref,id))
      swal({
          title: "Carousel Deleted",
          icon: "success",
          button: false,
          timer: 3000
      })
  
  }catch (e){
      swal({
          title: e,
          icon: "error",
          button: false,
          timer: 3000
      })

  }
      if(state == true){
          setState(false)
      }else{
          setState(true)
      }
      setData([])
  
   

}

// Delete data from carousel end


// Logout function
const navigate = useNavigate();
function logout() {
  signOut(auth)
    .then((res) => {
      navigate("/login");
    })
    .catch((err) => console.log(err));
}
// Logout end
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Gallery
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div">
            Shikhar Power
          </Typography>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[{ name: 'Carousel', link: '/admin/carousel' }, { name: 'Company Overview', link: '/admin/companyoverview' }, { name: 'Home Gallery', link: '/admin/gallery' }, { name: 'About Company', link: '/admin/about' }, { name: 'Vision and Mission', link: '/admin/visionmission' }, { name: 'Board of Directors', link: '/admin/boardofdirector' }, { name: 'Management Team', link: '/admin/managementteam' }, { name: 'Project', link: '/admin/project' }, { name: 'Gallery', link: '/admin/gallery' }, { name: 'News', link: '/admin/news' }, { name: 'Reports', link: '/admin/report' }, { name: 'Contact Us', link: '/admin/contactus' }].map((text, index) => (
            <Link to={text.link} className='menubuttonlink'>
              <ListItem key={text.name} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  className='menubutton'
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >

                  <ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }} />

                </ListItemButton>
              </ListItem>
            </Link>

          ))}
          <ListItem
            disablePadding
            sx={{ display: "block", background: "red", color: "#fff" }}
            onClick={logout}
          >
            <ListItemButton
              className="menubutton"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />

      </Drawer>
      {/* Content Start */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div className='galleyimageform' style={{ width: '100%', height: "auto", padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column' }}>
          {/* <div style={{height:'150px', width:'500px', backgroundColor:'green'}}> */}
          <form class="form">
            <span class="form-title">Upload your file</span>
            <p class="form-paragraph">
              File should be an image
            </p>
            <label for="file-input" class="drop-container">
              <span class="drop-title">Drop files here</span>
              or
              <input type="file" accept="image/*" required="" id="file-input" onChange={(e)=>setImg(e.target.files[0])} />
            </label>
          </form>
          {/* </div> */}
          <button className='submitimagebtn' onClick={handlesubmit}>Upload</button>
        </div>


        {/* <Button variant="contained" sx={{mb:3}} onClick={handleOpenform}>ADD</Button> */}




        {/* Data Grid Started 
      here we will show Data
      */}
      <h1>Images</h1>
       <ImageList sx={{ width: '100%' }} cols={3} >
      {data.map((item,i) => (
       
        <ImageListItem key={i}>
          <img
            src={`${item.data.Image}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.data.Image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={i}
            loading="lazy"
          />
        <button style={{backgroundColor:'#d32b2b', color:'#fff', height:'40px', margin:'10px 0', border:'none', fontSize:'1.5em'}} onClick={()=>deleteGallerydata(item.id)}>Delete</button>

        </ImageListItem>
      ))}
    </ImageList>

        {/* End Table Data */}


        {/* For Dialog box to add data */}
        {/* <Dialog
      fullScreen
      open={openform}
      onClose={handleCloseform}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ pl: 2, flex: 1, }} >
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Carousel
          </Typography>
         
        </Toolbar >
      </AppBar>
      <Stack
       direction="Column"
       justifyContent="center"
       alignItems="center"
       marginTop={10}
       spacing={5}
      >
          <Stack
          width={500}
          spacing={2}
          >

          <TextField  fullWidth type='file' id="image" border='none'  />
          <TextField  fullWidth id=" title" label="Title" variant="outlined" />
          <TextField  fullWidth id="description" label="Description" variant="outlined" />
          <Stack
          spacing={2}
          >
              <Button variant="contained" size="large" onClick={handleCloseform}>SAVE</Button>
              <Button variant="outlined" size="large" onClick={handleCloseform}>Cancel</Button>
              
          
          </Stack>
          </Stack>

      </Stack>
    </Dialog> */}
        {/* End dialog box to add data */}



      </Box>
      {/* Content End */}
    </Box>
  )
}



export default Gallery