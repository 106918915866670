import React from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Home from './Pages/Home/Home';
import About from './Pages/About Company/About';
import Bod from './Pages/Board of Director/Bod';
import Contactus from './Pages//Contact Us/Contactus';
import Gallery from './Pages/Gallery/Gallery';
import Managementteam from './Pages/Management Team/Managementteam';
import News from './Pages/News/News';
import Firstproject from './Pages/Project1/Firstproject';
import SecondProject from './Pages/Project2/Secondproject';
import Report from './Pages/Reports/Reports';
import Visionmission from './Pages/Vision and Mission/Visionmission';
import Login from '../Admin/Pages/Login/Login';
// import Pdfviewer from './Pages/Pdfviewer/Pdfviewer';



const Website = () => {
  return (
    <> 
    
    <Router>
      <Routes>
        <Route exact path='/about-us' Component={About}/>
        <Route exact path='/board-of-director' Component={Bod}/>
        <Route exact path='/contact-us' Component={Contactus}/>
        <Route exact path='/gallery' Component={Gallery}/>
        <Route exact path='/management-team' Component={Managementteam}/>
        <Route exact path='/news' Component={News}/>
        <Route exact path='/bhim-khola' Component={Firstproject}/>
        <Route exact path='/lower-bhim-khola' Component={SecondProject}/>
        <Route exact path='/report' Component={Report}/>
        <Route exact path='/vision-mission' Component={Visionmission}/>
        <Route exact path='/' Component={Home}/>
        <Route exact path='/login' Component={Login}/>
        {/* <Route exact path='/pdf-viewer' Component={Pdfviewer}/> */}
      </Routes>
      
    </Router>
    
      
    </>
  )
}

export default Website
