import logo from './logo.svg';
import './App.css';
import Admin from './Views/Admin/Admin';
import Website from './Views/Website/Website'
import './App.scss'
import { useEffect, useState } from 'react';
import { auth } from './Views/Firebase/Firebase';

const App = ()=> {
  const [isAuthenticated, setAuthenticated] = useState(false);
  useEffect(()=>{
    auth.onAuthStateChanged(user=>{
      if(user){
        setAuthenticated(true)
        
      }
    
    }
    
    )
    
    
  },[])
  return (
    <>

    {
      isAuthenticated?
      
      <Admin/>
      
      

      :
      <>
      
      </>
      

    }
    <Website/>
   
    </>
  );
}

export default App;
