import React, {useState, useEffect} from "react";
import Carousel from "react-bootstrap/Carousel";
import './Carousel.scss';
import img1 from '../../../../Assets/IMG3.jpg'
import { getDocs } from 'firebase/firestore'
import { carosealref } from '../../../Firebase/Firebase';


export default function Movingcarousel() {
  // Get about company from firebase database

const [data, setData] = useState([])
    
useEffect(()=>{
    async function getData(){
        const _data = await getDocs(carosealref)
       _data.forEach((element) => {
        setData((prv)=>[...prv,{data:element.data(), id:element.id}])
       });
       
       
    }
    getData()
   


},[]);

// Get about company from firebase end
 

  return (
    <Carousel variant="dark" className="caousel-container">
      {data.map((e)=>(
        <Carousel.Item className="carousel-item">
        <img
          className=" d-block carouselimage"
          src={e.data.image}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5 className="animate__bounceIn">{e.data.title}</h5>
          <p>{e.data.description}</p>
        </Carousel.Caption>
      </Carousel.Item>

      ))}
      {/* <Carousel.Item className="carousel-item">
        <img
          className=" d-block carouselimage"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5 className="animate__bounceIn">First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block carouselimage"
          src={img1}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block carouselimage"
          src={img1}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}
