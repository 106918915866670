import React, {useEffect, useState} from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import {Image} from 'react-bootstrap'
import Footer from '../../Components/Footer/Footer';
import { getDocs } from 'firebase/firestore'
import { managementteamref } from '../../../Firebase/Firebase';
import './managementteam.scss'


const Managementteam = () => {
    // Get about company from firebase database

const [data, setData] = useState([])
    
useEffect(()=>{
    async function getData(){
        const _data = await getDocs(managementteamref)
       _data.forEach((element) => {
        setData((prv)=>[...prv,{data:element.data(), id:element.id}])
       });
       
       
    }
    getData()
   


},[]);

// Get about company from firebase end
  return (
    <>
    <div className='fullscroll fullscrollmanagementteam'>
    
    <div className='Section' style={{height:'auto', minHeight:'100vh'}}>
    <Navigationbar/>
    <div className='center'>

          <div className="topic title">
            <h2>Management Team</h2>
          </div>
          </div>

   {
    data.map((e)=>(
      <div style={{display:'flex',justifyContent:'center', alignItems:'center',flexDirection:'column'}}>
       
      <img src={e.data.image} className='mtimage'/>
      </div>
    ))
   }
    </div>
    {/* <div className='Section'>
      Page 2
    </div>
    <div className='Section'>
      Page 3
    </div> */}
    <div className='Section footerfullscreeen'>
        <Footer/>
      </div>
    </div>
    </>
  )
}

export default Managementteam
