import React, { useState, useEffect } from 'react'
import Navigationbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import './Aboutus.scss'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { getDocs } from 'firebase/firestore'
import { aboutcompanyref, messagefromref } from '../../../Firebase/Firebase';



const About = () => {
  // Get about company from firebase database

  const [data, setData] = useState([])
  const [messagefrom, setMessaagefrom] = useState([])

  useEffect(() => {
    async function getData() {
      const _data = await getDocs(aboutcompanyref)
      _data.forEach((element) => {
        setData((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }
    async function getmessagefromData() {
      const _data = await getDocs(messagefromref)
      _data.forEach((element) => {
        setMessaagefrom((prv) => [...prv, { data: element.data(), id: element.id }])
      });


    }
    getData()
    getmessagefromData()



  }, []);
  

  // Get about company from firebase end


  return (
    <div className='fullscroll fullscrollabout'>

      <div className='Section'>
        <Navigationbar />
        <div className='aboutustext'>
          <div>
            <h1>About Us</h1>
            {
              data.map((e) => (
                <p dangerouslySetInnerHTML={{ __html: e.data.about }}></p>
              ))
            }

            {/* <p>
      Shikhar Power Development Pvt.. Ltd. (SPDPL) was established in 2071-05-26 B.S. (September- 11-2015 A.D.) in Kathmandu, Nepal with the registration no. 111336/068/070 under the Company Act 2063 of the government of Nepal. The Company was established with the aim of harnessing hydropower potential of the country in order to contribute for the development of the country.<br/><br/><br/>

      SPDPL will be initially generating 4.96 MW and its cascade project will be 7.1 MW. In the same way, we are moving forward with the determination to progressively increase the generating capacity in the long run. Preliminary estimate has shown that about 42,000-50,000 MW of hydroelectricity could be generated in Nepal which is feasible techno-economically. But on the contrary, only 2% - 3% of the total capacity is produced in our country which is very minimalistic. This great possibility of hydroelectricity is due to the continuous rivers flowing from north to south of the country with favorable geographical topography for the generation of hydroelectric projects. Understanding such huge potential, SPDPL is ambitious in taking the country one step forward in its development by providing a bright and prosperous future for us all.
      </p> */}
          </div>


        </div>
        {/* <h1>hello</h1> */}
      </div>
      {
        messagefrom.map((e)=>(
          
          <div className='Section message'>
        <div>

          <div className='mfimage'>
            <img src={console.log(e.data.image)} />
            <h4>{e.data.name}</h4>
            <p>{e.data.designation}</p>
          </div>
          <div className='mftext'>
            <span><FormatQuoteIcon /></span>
            <span className='text' dangerouslySetInnerHTML={{ __html: e.data.message }}></span>
            <span><FormatQuoteIcon /></span>
          </div>
        </div>
      </div>
          
        ))
      }
      
      {/* <div className='Section'>
        <div>

          <div className='mfimage'>
            <img src='' />
          </div>
          <div className='mftext'>
            <span><FormatQuoteIcon /></span>
            <span className='text'> hello</span>
            <span><FormatQuoteIcon /></span>
          </div>
        </div>
      </div> */}
      {/*  */}
      {/* <div className='Section'>
        <div>


          <div className='mftext'>
            <span><FormatQuoteIcon /></span>
            <span className='text'> hello</span>
            <span><FormatQuoteIcon /></span>
          </div>
          <div className='mfimage'>hi im image</div>

        </div>

      </div> */}
      <div className='Section footerfullscreeen'>
        <Footer />
      </div>
    </div>
  )
}

export default About
