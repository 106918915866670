import React, { useState } from 'react'
import './Login.scss'
import Navigationbar from '../../../Website/Components/Navbar/Navbar'
import Footer from '../../../Website/Components/Footer/Footer'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../../Firebase/Firebase'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState({
    email:'',
    password:''
  })

  function handlesubmit(){
    if(!values.email || !values.password){
      return;
    }
    signInWithEmailAndPassword(auth,values.email, values.password).then(res=>{
      console.log(res)
    // console.log(values)
    navigate('/admin')

      
    }).catch((err)=>alert(err.message))
    
  }
  return (
    <>
        <Navigationbar/>

    <div className='adminlogin'>
        <div class="form">
  <p>Login</p>
  <div class="group">
    <input required="true" class="main-input" type="text" onChange={(e)=>setValues({...values, email: e.target.value})}/>
    <span class="highlight-span"></span>
    <label class="lebal-email" >Email</label>
  </div>
  <div class="container-1">
    <div class="group">
      <input required="true" class="main-input" type="password" onChange={(e)=>setValues({...values, password: e.target.value})}/>
      <span class="highlight-span"></span>
      <label class="lebal-email" >password</label>
    </div>
  </div>
  <div>

  <button class="submit" onClick={handlesubmit}>submit</button>
  </div>
  <span className='forgetpassword'>
    <a href=''>Forget Password?</a>
  </span>
</div>
      
    </div>
    <Footer/>
    </>
  )
}

export default Login
