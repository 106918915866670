import React from 'react'

const Contactus = () => {
  return (
    <div>
      Contactus
    </div>
  )
}

export default Contactus
