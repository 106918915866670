import React, { useEffect, useState } from 'react'
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import './Gallery.scss'
import Navigationbar from '../../Components/Navbar/Navbar'
import { getDocs } from 'firebase/firestore';
import { galleryref } from '../../../Firebase/Firebase';
import Footer from '../../Components/Footer/Footer';



export default function Gallery() {
  const [gallerydata, setGallerydata] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    
    async function getGallerydata() {
      const _data = await getDocs(galleryref)
      _data.forEach((element) => {
        setGallerydata((prv) => [...prv, { data: element.data(), id: element.id }])
        setLoading(true)
      });


    }


   
   
    getGallerydata()




  }, []);
  
  return (
    
    <div id='gallerymain'>
      <Navigationbar/>
        {/* <Header name="Gallery"/> */}
        <div className='galleryview'>

        {loading && (
          <SlideshowLightbox className="gallery" >

      
        {gallerydata.map((e,i) => (
          
          <img
          src={`${e.data.Image}`}
          srcSet={`${e.data.Image}`}
          alt={e.data.title}
          loading="lazy"
          key={i}
          />
          
          ))}
       

        
      
      </SlideshowLightbox>
      
      )}
      </div>
      <Footer/>

      </div>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
    title: 'Bed',
  },
  {
    img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
    title: 'Books',
  },
  {
    img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
    title: 'Sink',
  },
  {
    img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
    title: 'Kitchen',
  },
  {
    img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
    title: 'Blinds',
  },
  {
    img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
    title: 'Chairs',
  },
  {
    img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
    title: 'Laptop',
  },
  {
    img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
    title: 'Doors',
  },
  {
    img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
    title: 'Storage',
  },
  {
    img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
    title: 'Candle',
  },
  {
    img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    title: 'Coffee table',
  },
];



